import React, { Fragment, Component } from "react";
import { withTranslation } from "react-i18next";
import ReactPixel from "react-facebook-pixel";
import PropTypes from "prop-types";
import ListItems from "./ListItems";
import SeatsMap from "./SeatsMap";
import FreeAssignment from "./FreeAssignment";
import Purchase from "./Purchase";
import moment from "moment";
import Services from "../../services/Services";
import Footer from "../../components/Footer";
import GoTop from "../../components/GoTop";
import Timer from "../../components/Timer";
import Skeleton from "react-loading-skeleton";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Tienda from "../Tickets/tienda";
import {
    readCookie,
    deleteCookie,
    currencyFormatDE,
    currencyFormatValue,
    urlFunction,
} from "../../utils/globals";
import ModalDialog from "../../components/ModalDialog";
import Header from "./../../components/Header";
import { ShoppingCart,NewBack } from "../../utils/iconsSvg";


class TicketsPre extends Component {
    state = {
        showID: null,
        eventId: null,
        purchase: false,
        ShapeId: null,
        selectedTickets: false,
        imgEvent: "",
        toggleWrap: false,
        khipuLoading: false,
        isLoadingItems: 0,
        error: false,
        allowed: false,
        showSkeleton: true,
        maxBuy: false,
        mapaSvgImg: "",
        freeTickets: null,
        currentFunction: null,
        confirmBack: false,
        showTienda: false,
        errorFuncion: false,
        reserving:false
    };
    RSwal = withReactContent(Swal);
    closeSkeleton = (val) => {
        this.setState({
            showSkeleton: false,
        });
    };

    async UNSAFE_componentWillMount() {
        const { reservationSeats, global } = this.props;

        let host = window.location.href.split("host=")[1];
        // reservationSeats.forEach(
        //     async (rs) => await this.cancelReservation(rs)
        // );
        const transaccion = window.location.pathname.split("/")[4];
        const canal = window.location.pathname.split("/")[5];
        if (transaccion && canal == 2) {
            this.setState({ ...this.state, khipuLoading: true });
            const paymentId = readCookie("idKhipu");
            const interval = setInterval(async () => {
                const response = await Services.pay.verificarPagoKhipu(
                    paymentId
                );
                if (response.status === 200) {
                    if (
                        response.data.Result &&
                        response.data.TransaccionId > 0
                    ) {
                        clearInterval(interval);
                        deleteCookie("idKhipu");
                        this.props.history.push({
                            pathname: `/compraexitosa/${response.data.TransaccionId}/2`,
                            state: { confirmacion: response.data.Confirmacion },
                        });
                    } else {
                        console.log(response.data);
                    }
                }
            }, 3000);
        } else if (transaccion && canal == 1) {
            const paramError = window.location.pathname.split("/")[6];
            if (
                (paramError === null ||
                    paramError === undefined ||
                    parseInt(paramError, 10) === 0) &&
                parseInt(transaccion, 10) > 0
            ) {
                this.props.history.push(`/compraexitosa/${transaccion}/`);
            } else {
                this.setState({ error: true });
            }
        } else {
            const { eventId } = this.props.match.params;
            this.setState({ eventId });
            if (eventId) {
                this.props.getEvent(eventId);
                Services.config
                    .getDetailSkin(eventId)
                    .then((response) => {
                        if (response.status === 200) {
                            this.setState({
                                allowed: !response.data.RequiereCola,
                                imgEvent: `https://ticketmundoblob.blob.core.windows.net/imagenestmusaprueba/Evento_${eventId}_517_262.jpeg`,
                            });
                            // this.props.setFunction(response.data);
                            // this.setState({
                            //   allowed: true,
                            // });
                            const resource =
                                response.data.NombreCola || "KG-2024-1";
                            if (response.data.RequiereCola) {
                            // if (false) {
                                if (host === undefined) {
                                    window.location.href =
                                        response.data.UrlLanding;
                                }

                                Services.waiting
                                    .check(host)
                                    .then((rspApiSIM) => {
                                        // console.log(rspApiSIM.data);
                                        if (rspApiSIM.status === 200) {
                                            if (
                                                rspApiSIM.data.is_allowed &&
                                                resource ===
                                                    rspApiSIM.data.resource
                                            ) {
                                                this.setState({
                                                    allowed: true,
                                                });
                                            } else {
                                                // console.log("redirect");
                                                window.location.href =
                                                    response.data.UrlLanding;
                                            }
                                        } else {
                                            this.setState({
                                                allowed: true,
                                            });
                                        }
                                    });
                            } else {
                                this.setState({
                                    allowed: true,
                                });
                            }
                        } else {
                            console.log(response.status);
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    });

                // Services.config.getEventsByFunctions(eventId).then;
            }
        }
    }

    changeFunction = (funcS) => {
        this.setState({
            freeTickets: null,
        });
        Services.shows
            .getShowById(funcS.FuncionId, this.props.global.Idioma)
            .then((response) => {
                // console.log(response);
                if (response.status === 200) {
                    if (
                        (response.data.Iso === null ||
                            response.data.Iso === undefined) &&
                        !response.data.RequiereCola
                    ) {
                        if (!response.data.RequiereCola) {
                            this.props.history.push(
                                `/event/${response.data.EventoId}`
                            );
                        }
                    } else {
                        this.setState({
                            allowed: true,
                            imgEvent: `https://ticketmundoblob.blob.core.windows.net/imagenestmusaprueba/Evento_${response.data.EventoId}_517_262.jpeg`,
                            currentFunction: response.data,
                        });
                        // this.props.setFunction(response.data);
                        this.props.setFunction(response.data);
                        if (
                            funcS.MapaSvgId === 0 ||
                            (funcS.MapaSvgId === null &&
                                response.data !== this.props.selectedFunction)
                        ) {
                            Services.tickets
                                .getFreeAssignmentTickets(funcS.FuncionId)
                                .then((resp) => {
                                    this.setState({
                                        freeTickets: resp.data,
                                    });
                                });
                        } else {
                            this.setState({ freeTickets: null });
                        }
                    }
                } else {
                    console.log(response.status);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    componentDidMount() {
        // console.log(
        //     this.props.global,
        //     "------------------------------------------"
        // );
        const { eventId } = this.props.match.params;
        ReactPixel.pageView();
        ReactPixel.track("ViewContent");

        this.getMapSVG(eventId);
    }

    componentDidUpdate(nextprop, nextState) {
        if (this.state.mapaSvgImg !== "") {
            let wrapMapaSvg = document.getElementById("wrapMapaSvg");
            if (wrapMapaSvg) {
                let zones =
                    wrapMapaSvg.getElementsByClassName("sectionZoneSvg");
                Array.from(zones).forEach((z, idx) => {
                    let funcS = this.props.functions.find(
                        (f) => f.FuncionId === parseInt(z.id)
                    );
                    if (funcS) {
                        z.addEventListener("click", () => {
                            if (funcS) {
                                this.changeFunction(funcS);
                                // console.log(funcS);
                            }
                        });
                        z.classList.remove("disabled");
                    } else {
                        z.classList.add("disabled");
                    }
                });
            }
        }
        // if (reservs.length && this.props.reservationSeats.length === 0) {
        //     // reservs.map((r) => this.props.setReservation(r));
        // }
        // if (this.props.functions[0]) {
        //     let reservs =
        //         JSON.parse(localStorage.getItem("reservationSeats")) || [];
        //     const now = new Date();
        //     setTimeout(() => {
        //         if (
        //             reservs.length &&
        //             this.props.reservationSeats.length === 0
        //         ) {
        //             if (now.getTime() > reservs[0].expiry) {
        //                 // If the item is expired, delete the item from storage
        //                 // and return null
        //                 localStorage.removeItem("reservationSeats");
        //                 return null;
        //             } else {
        //                 this.props.refreshReservation(reservs);
        //             }
        //         }
        //     }, 500);
        // }
    }

    getMapSVG = async (eventId) => {
        const { data, status } = await Services.tickets.getMapSvgImage(eventId);
        if (status === 200) {
            this.setState({ mapaSvgImg: data });
        }
    };

    setLoader = (val) => {
        this.setState({
            ...this.state,
            isLoadingItems:
                this.state.isLoadingItems + val < 0
                    ? 0
                    : this.state.isLoadingItems + val,
        });
    };

    handleToggleWrap = () => {
        this.setState({ toggleWrap: !this.state.toggleWrap });
    };

    handleToggleWrapOut = () => {
        if (this.state.toggleWrap === true) {
            this.setState({ toggleWrap: !this.state.toggleWrap });
        }
    };

    handleSelectTicket = () => {
        this.setState({ ...this.state, selectedTickets: true });
    };
    cancelReservation = async (item) => {
        this.setLoader(1);

        try {
            if (item) {
                if (document.getElementsByTagName("iframe")[0]) {
                    document
                        .getElementsByTagName("iframe")[0]
                        .contentWindow.postMessage(
                            item.ShapeId.toString(),
                            "*"
                        ); //desmarcar puesto en el mapa SIMPP

                    this.setState({ ShapeId: item.ShapeId });
                }
                this.props.deleteReservation(item);
                if (item.bloqueado !== true) {
                    const cancelReservation =
                        await Services.reservations.cancelReservation(item.Id);
                }
            }
            this.setLoader(-1);
        } catch (error) {
            this.props.setReservation(item);
            if (document.getElementsByTagName("iframe")[0]) {
                document
                    .getElementsByTagName("iframe")[0]
                    .contentWindow.postMessage(
                        { PuestosSeleccionados: [item.ShapeId] },
                        "*"
                    ); //Marcar puesto en el mapa SIMPP
            }
            this.setLoader(-1);
        }
    };

    handlePay = async () => {
        this.setState({reserving:true})
        ReactPixel.pageView();
        ReactPixel.fbq("track", "AddToCart");
        const groupByTypeId = this.props.reservationSeats
            .filter((p) => p.bloqueado)
            .reduce((group, reservation) => {
                const { TipoEntradaId } = reservation;
                group[TipoEntradaId] = group[TipoEntradaId] ?? [];
                group[TipoEntradaId].push(reservation);
                return group;
            }, {});
        const body = {
            CanalId: this.props.global.Canalid,
            MontoConversion: 0,
            Reservaciones: [],
        };

        const Reservaciones = Object.keys(groupByTypeId).map((key) => {
            return {
                FuncionId: groupByTypeId[key][0].funcionId,
                TipoEntradaId: Number(key),
                Puestos: groupByTypeId[key].map((p) => p.ShapeId),
            };
        });

        body.Reservaciones = Reservaciones;
        const { data: dataRes, status } =
            await Services.reservations.createReservationV2(body);
        if (status === 200) {
            console.log("actualizando reservas", dataRes.Tickets);
            this.props.changeBloquedToReserve(dataRes.Tickets);
            if (
                this.props.selectedFunction.DDBranchId &&
                !this.state.showTienda
            ) {
                this.setState({
                    ...this.state,
                    showTienda: true,
                    purchase: false,
                });
            } else {
                this.setState({
                    ...this.state,
                    purchase: true,
                    showTienda: false,
                });
            }
            // this.setState({ ...this.state, purchase: true });
        }
        this.setState({reserving:false})
    };

    BtnBack = async () => {
        this.setState({ purchase: false, showTienda: false });
        setTimeout(() => {
            if (document.getElementById("mapa-boletos-wrap") === null) {
                const { reservationSeats } = this.props;
                reservationSeats.forEach(
                    async (rs) =>
                        await Services.reservations
                            .cancelReservation(rs.Id)
                            .then(this.props.deleteReservation(rs))
                );
            }
        }, "500");
    };

    backFunction = () => {
        if (this.props.reservationSeats.length > 0) {
            this.setState({ confirmBack: true });
        } else {
            this.props.setFunction(null);
        }
    };

    clearTickets = () => {
        this.props.reservationSeats.forEach(
            async (rs) => await this.cancelReservation(rs)
        );
        localStorage.removeItem("reservationSeats");
        this.setState({ confirmBack: false });
        this.props.setFunction(null);
    };

    render() {
        const {
            selectedFunction,
            global,
            setReservation,
            reservationSeats,
            setResumeTickets,
            resumeTickets,
            t,
        } = this.props;
        const { cancelReservation, handlePay, handleSelectTicket, setLoader } =
            this;
        const {
            eventId,
            purchase,
            ShapeId,
            selectedTickets,
            imgEvent,
            khipuLoading,
            isLoadingItems,
            showTienda,
        } = this.state;
        let date = null;
        date = this.props.functions[0]
            ? moment(this.props.functions[0].Fecha)
            : null;

        var MontoTotal = 0;
        var ComisionTotal = 0;
        reservationSeats &&
            reservationSeats.forEach((element) => {
                if (element) {
                    if (
                        global.mostrarCargos &&
                        this.props.functions[0].TipoDesglose !== 2
                    ) {
                        MontoTotal =
                            this.props.functions[0].Iso === "VES"
                                ? global.MonedaPorDefecto === "USD"
                                    ? MontoTotal +
                                      (currencyFormatValue(
                                          global.dolarToday,
                                          element.Precio,
                                          global.MostrarDecimales
                                      ) +
                                          currencyFormatValue(
                                              global.dolarToday,
                                              element.Comision,
                                              global.MostrarDecimales
                                          ))
                                    : MontoTotal +
                                      (element.Precio + element.Comision)
                                : MontoTotal +
                                  (element.Precio + element.Comision);
                    } else if (this.props.functions[0].TipoDesglose === 2) {
                        MontoTotal =
                            this.props.functions[0].Iso === "VES"
                                ? global.MonedaPorDefecto === "USD"
                                    ? MontoTotal +
                                      currencyFormatValue(
                                          global.dolarToday,
                                          element.Precio +
                                              element.Comision -
                                              (element.MontoIVA +
                                                  element.ComisionIVA),
                                          global.MostrarDecimales
                                      )
                                    : MontoTotal +
                                      (element.Precio +
                                          element.Comision -
                                          (element.MontoIVA +
                                              element.ComisionIVA))
                                : MontoTotal +
                                  (element.Precio +
                                      element.Comision -
                                      (element.MontoIVA + element.ComisionIVA));
                    } else {
                        
                        MontoTotal =
                            this.props.functions[0].Iso === "VES"
                                ? global.MonedaPorDefecto === "USD"
                                    ? MontoTotal +
                                      currencyFormatValue(
                                          global.dolarToday,
                                          element.Precio,
                                          global.MostrarDecimales
                                      )
                                    : MontoTotal + element.Precio
                                : MontoTotal + element.Precio;
                    }
                    ComisionTotal += (MontoTotal * element.Valor) / 100;
                }
            });

        return (
            <Fragment>
                <GoTop />
                <Header customStyle={`tk_events`} backBtn={
                <span className="responsive_back" onClick={()=> this.BtnBack()}>
                    <NewBack />
                </span>
                }/>
                <section id="main" className="main-mapa">
                    <header className="clear-fl bg-ppal tm-header">
                        <div className="container-custom-tm">
                            <div className="banner-evento">
                                <figure className="img-evento">
                                    {imgEvent.trim() !== "" && (
                                        <img
                                            src={imgEvent}
                                            alt=""
                                            className="logo"
                                        />
                                    )}
                                </figure>
                                <div className="info-evento clear-fl d-flex h-100">
                                    <div className="datos color-titulos flex-grow-1">
                                        <div>
                                            <div
                                                className="title sub-title-bold"
                                                id="eventBanner"
                                            >
                                                {this.props.functions[0]
                                                    ? this.props.functions[0]
                                                        .EventoNombre
                                                    : ""}
                                            </div>
                                            <div
                                                className="fecha text-14-153 color-texto-contenido"
                                                style={{
                                                    textTransform: "capitalize",
                                                }}
                                            >
                                                {selectedFunction &&
                                                (selectedFunction.EventoNombre.toLowerCase().indexOf(
                                                    "abonado"
                                                ) >= 0 ||
                                                    selectedFunction.EventoNombre.toLowerCase().indexOf(
                                                        "abono"
                                                    ) >= 0)
                                                    ? "Todas las fechas"
                                                    : date
                                                    ? date.format("dddd DD MMMM") +
                                                    " " +
                                                    moment
                                                        .utc(date)
                                                        .format(
                                                            this.props.global
                                                                .formatoFecha ===
                                                                "12hr"
                                                                ? "hh:mm"
                                                                : "HH:mm"
                                                        ) +
                                                    " hrs"
                                                    : ""}
                                            </div>
                                            <div className="direccion text-14-153 color-texto-contenido">
                                                {this.props.functions[0]
                                                    ? this.props.functions[0]
                                                        .RecintoNombre
                                                    : ""}
                                            </div>
                                            {selectedFunction && (
                                                <a
                                                    href={
                                                        this.props.functions[0]
                                                            .UrlLanding &&
                                                        this.props.functions[0].UrlLanding.trim() !==
                                                            ""
                                                            ? this.props
                                                                .functions[0]
                                                                .UrlLanding
                                                            : `${global.logoRedirect}event/${this.props.functions[0].EventoId}`
                                                    }
                                                    className="back color-ppal"
                                                >
                                                    {t("homeLink")}
                                                </a>
                                            )}
                                        </div>
                                    </div>
                                    {/*selectedTickets ? (
                                        <Timer
                                            global={global}
                                            redirect={
                                                this.props.functions[0]
                                                    .RequiereCola
                                                    ? this.props.functions[0]
                                                          .UrlLanding
                                                    : false
                                            }
                                        />
                                    ) : (
                                        
                                    )*/}
                                    <figure className="logo-ext">
                                    {
                                        global.logo ? <img src={global.logo} alt="logo-ext" /> : null
                                    }
                                    </figure>
                                </div>
                            </div>
                        </div>
                    </header>

                   
                    {showTienda ? (
                        <Tienda
                            cancelReservation={cancelReservation}
                            deleteReservation={this.props.deleteReservation}
                            setResumeTickets={setResumeTickets}
                            setTotalMount={this.props.setTotalMount}
                            // showID={showID}
                            back={() => this.BtnBack()}
                            goPurchase={handlePay}
                        />
                    ) : purchase && this.state.currentFunction ? (
                        <Purchase
                            showID={this.state.currentFunction.FuncionId}
                            closeSkeleton={this.closeSkeleton}
                            showSkeleton={this.state.showSkeleton}
                            reservationSeats={reservationSeats}
                            multipleFuncion={true}
                            selectedFunction={this.state.currentFunction}
                            back={() => this.BtnBack()}
                        />
                    ) : this.state.allowed ? (
                        <Fragment>
                            <section
                                id="wrap-ppal"
                                className="pageMapa"
                                onClick={this.handleToggleWrapOut}
                            >
                                <div id="wrap-cont">
                                    <div className="container-custom-tm clear-fl">
                                        <div id="mapa-boletos-wrap">
                                            <div
                                                className="mapa"
                                                style={{
                                                    position: "relative",
                                                }}
                                            >
                                                <div className="container-map container-map-pres">
                                                    {selectedFunction ? (
                                                        <div className="btn-back-svg">
                                                            <button
                                                                onClick={
                                                                    this
                                                                        .backFunction
                                                                }
                                                            >
                                                                <svg
                                                                    width="19"
                                                                    height="17"
                                                                    viewBox="0 0 19 17"
                                                                    fill="none"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                >
                                                                    <path
                                                                        d="M8.89551 15.689L1.55096 8.34442L8.89554 0.999836"
                                                                        stroke="#333"
                                                                        strokeWidth="2"
                                                                    />
                                                                    <line
                                                                        x1="3"
                                                                        y1="8.40771"
                                                                        x2="19"
                                                                        y2="8.40771"
                                                                        stroke="#333"
                                                                        strokeWidth="2"
                                                                    />
                                                                </svg>
                                                            </button>
                                                            <h3>
                                                                {
                                                                    selectedFunction.Extra
                                                                }
                                                            </h3>
                                                        </div>
                                                    ) : null}
                                                    {selectedFunction ? (
                                                        selectedFunction.MapaSvgId >=
                                                        1 ? (
                                                            <SeatsMap
                                                                selectedFunction={
                                                                    selectedFunction
                                                                }
                                                                handleSelectTicket={
                                                                    handleSelectTicket
                                                                }
                                                                middleWareSeat={() => {
                                                                    this.setState(
                                                                        {
                                                                            maxBuy: true,
                                                                        }
                                                                    );
                                                                }}
                                                                noPaintSeat={(
                                                                    item
                                                                ) => {
                                                                    this.props.deleteReservation(
                                                                        item
                                                                    );
                                                                }}
                                                                setLoader={
                                                                    setLoader
                                                                }
                                                                setReservation={
                                                                    setReservation
                                                                }
                                                                cancelReservation={
                                                                    cancelReservation
                                                                }
                                                                items={
                                                                    reservationSeats
                                                                }
                                                                showID={
                                                                    selectedFunction.FuncionId
                                                                }
                                                                shapeId={
                                                                    ShapeId
                                                                }
                                                                global={
                                                                    this.props
                                                                        .global
                                                                }
                                                                dolarToday={
                                                                    this.props
                                                                        .global
                                                                        .dolarToday
                                                                }
                                                                MonedaPorDefecto={
                                                                    this.props
                                                                        .global
                                                                        .MonedaPorDefecto
                                                                }
                                                                language={this.props.global.Idioma.toLowerCase()}
                                                                setResumeTickets={
                                                                    this.props
                                                                        .setResumeTickets
                                                                }
                                                                resumeTickets={
                                                                    this.props
                                                                        .resumeTickets
                                                                }
                                                                reservationSeats={
                                                                    this.props
                                                                        .reservationSeats
                                                                }
                                                                setTotalMount={
                                                                    this.props
                                                                        .setTotalMount
                                                                }
                                                            />
                                                        ) : this.state
                                                              .freeTickets ? (
                                                            <FreeAssignment
                                                                showID={
                                                                    selectedFunction.FuncionId
                                                                }
                                                                Simbolo={
                                                                    selectedFunction.MonedaSimbolo
                                                                }
                                                                Iso={
                                                                    selectedFunction.Iso
                                                                }
                                                                handleSelectTicket={
                                                                    this
                                                                        .handleSelectTicket
                                                                }
                                                                ReservationSeats={
                                                                    reservationSeats
                                                                }
                                                                setReservation={
                                                                    this.props
                                                                        .setReservation
                                                                }
                                                                setResumeTickets={
                                                                    this.props
                                                                        .setResumeTickets
                                                                }
                                                                FreeTickets={
                                                                    this.state
                                                                        .freeTickets ||
                                                                    []
                                                                }
                                                                resumeTickets={
                                                                    this.props
                                                                        .resumeTickets
                                                                }
                                                                pay={() => {
                                                                    this.RSwal.close();
                                                                }}
                                                                setTotalMount={
                                                                    this.props
                                                                        .setTotalMount
                                                                }
                                                                cantMaxEnt={
                                                                    selectedFunction.CantidadMaximaEntradasCanales
                                                                }
                                                                cancelReservation={
                                                                    cancelReservation
                                                                }
                                                                global={
                                                                    this.props
                                                                        .global
                                                                }
                                                                setLoader={
                                                                    setLoader
                                                                }
                                                            />
                                                        ) : null
                                                    ) : (
                                                        <div
                                                            id="wrapMapaSvg"
                                                            dangerouslySetInnerHTML={{
                                                                __html: this
                                                                    .state
                                                                    .mapaSvgImg,
                                                            }}
                                                        ></div>
                                                    )}
                                                </div>
                                            </div>
                                            <ListItems
                                                items={reservationSeats}
                                                resumeTickets={resumeTickets}
                                                pay={handlePay}
                                                selectedFunction={
                                                    this.props.functions[0]
                                                }
                                                Iso={
                                                    this.props.functions[0]?.Iso
                                                }
                                                Simbolo={
                                                    this.props.functions[0]
                                                        ?.MonedaSimbolo
                                                }
                                                cancelReservation={
                                                    cancelReservation
                                                }
                                                setResumeTickets={
                                                    setResumeTickets
                                                }
                                                toggleWrap={
                                                    this.state.toggleWrap
                                                }
                                                isLoadingItems={isLoadingItems}
                                                global={global}
                                                reserving={this.state.reserving}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </Fragment>
                    ) : (
                        <div id="wrap-cont">
                            <div className="container clear-fl ">
                                <div
                                    className="row"
                                    style={{ marginTop: "160px" }}
                                >
                                    <div className="col-xs-12 col-lg-8">
                                        <div className="skel-img">
                                            <Skeleton height={410} />
                                        </div>
                                    </div>
                                    <div className="col-xs-12 col-lg-4">
                                        <Skeleton height={200} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </section>
                <div id="footer-m">
                    <div
                        className="container-custom-tm ajust_flex"
                        onClick={this.handleToggleWrapOut}
                    >
                        {/* <div className="car show-seats">
                <span
                  onClick={() => this.handleToggleWrap()} 
                >
                  <i className="icon-carrito" style={{ color: "#fff" }} ></i>
                  {(reservationSeats.length) && <span>{reservationSeats.length}</span>}
                </span>
                <p className="" style={{ color: 'rgb(255,255,255)' }}>
                  {(MontoTotal || reservationSeats.length > 0) ? <span>$ {MontoTotal.toLocaleString('de-DE')}</span> : <span>No hay entradas</span>}
                </p>:
                  </div>
               */}
                        <div className="car color-b">
                            {isLoadingItems ? (
                                <div className="spinner-main spinner4">
                                    <div className="loadingio-spinner-rolling-k94er4xvtw spinner4-b">
                                        <div className="ldio-arie1i327iv spinner4-c">
                                            <div></div>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <span onClick={() => this.handleToggleWrap()} style={{width:'42px',height: '32px',alignItems: 'center', justifyContent: 'center', display: 'flex'}}>
                                    <ShoppingCart />
                                    <span style={{}}>
                                        {reservationSeats.length && (
                                            <span>
                                                {reservationSeats.length}
                                            </span>
                                        )}
                                    </span>
                                </span>
                            )}

                            <p>
                                {MontoTotal || reservationSeats.length > 0 ? (
                                    this.props.functions[0].TipoDesglose !==
                                    2 ? (
                                        <span>
                                            {this.props.functions[0].Iso ===
                                            "VES"
                                                ? this.props.global
                                                      .MonedaPorDefecto
                                                : this.props.functions[0]
                                                      .MonedaSimbolo}
                                            {currencyFormatDE(
                                                MontoTotal,
                                                this.props.global
                                                    .thousandsSeparator,
                                                this.props.global
                                                    .decimalSeparator,
                                                this.props.global
                                                    .MostrarDecimales
                                            )}
                                            {/* {global.mostrarCargos && (
                                                    <span> + {t("fee")}</span>
                                                )} */}
                                        </span>
                                    ) : (
                                        <span>
                                            {this.props.functions[0].Iso ===
                                            "VES"
                                                ? this.props.global
                                                      .MonedaPorDefecto
                                                : this.props.functions[0]
                                                      .MonedaSimbolo}
                                            {currencyFormatDE(
                                                MontoTotal + ComisionTotal,
                                                this.props.global
                                                    .thousandsSeparator,
                                                this.props.global
                                                    .decimalSeparator,
                                                this.props.global
                                                    .MostrarDecimales
                                            )}
                                        </span>
                                    )
                                ) : (
                                    <span>{t("tickets")}</span>
                                )}
                            </p>
                        </div>

                        <button
                            className={`btn-skin btn-get-tk-m btn-ppal ${
                                MontoTotal > 0 ? "active" : ""
                            }`}
                            onClick={() => {
                                if (reservationSeats.length >= 1) {
                                    handlePay();
                                }
                            }}
                            data-href="pago.html"
                        >
                            {this.state.reserving ? 
                            <div className="spinner-main spinner3">
                                <div className="loadingio-spinner-rolling-k94er4xvtw spinner3-b">
                                    <div className="ldio-arie1i327iv spinner3-c">
                                    <div
                                        style={{
                                        border: `10px`,
                                        borderStyle: "solid",
                                        borderColor: `#ffffff`,
                                        borderTopColor: "transparent",
                                        }}
                                    ></div>
                                    </div>
                                </div>
                            </div> 
                            : t("continue")}
                        </button>
                    </div>
                </div>
                <ModalDialog
                    display={this.state.error}
                    setDisplay={(error) => this.setState({ error: error })}
                    title={t("paymentError")}
                    content={t("paymentErrorMsg")}
                    extra={t("tryAgain").toUpperCase()}
                    buttonText={t("labelOK").toUpperCase()}
                    handleClick={() => (window.location.href = urlFunction(3))}
                />

                <ModalDialog
                    display={this.state.errorFuncion}
                    setDisplay={(error) => this.setState({ errorFuncion: error })}
                    title={t("Error en zona")}
                    content={t("Puede que la zona no tenga disponibilidad en este momento")}
                    extra={t("tryAgain").toUpperCase()}
                    buttonText={t("labelOK").toUpperCase()}
                    handleClick={() => this.setState({ errorFuncion: false })}
                />

                {selectedFunction && (
                    <div
                        className={
                            this.state.maxBuy
                                ? "modal fade modalAlert in"
                                : "modal fade modalAlert"
                        }
                        style={{
                            display: this.state.maxBuy ? "block" : "none",
                            zIndex: 99999999,
                        }}
                        id="modalConfirm"
                        role="dialog"
                    >
                        <div
                            className="modal-dialog modal-dialog-centered modal-md"
                            role="document"
                        >
                            <div className="modal-content">
                                <div className="conten-expired clear-fl">
                                    <div className="top">
                                        <i className="icon-alert color-b"></i>
                                    </div>
                                    <div className="content">
                                        <div className="title color-titulos">
                                            Ha alcanzado la cantidad máxima de
                                            tickets por compra.
                                        </div>
                                        <p className="color-texto-contenido">
                                            Este evento solo permite{" "}
                                            {
                                                selectedFunction?.CantidadMaximaEntradasCanales
                                            }{" "}
                                            entradas por usuario.
                                        </p>
                                        <a
                                            onClick={async () => {
                                                this.setState({
                                                    maxBuy: false,
                                                });
                                            }}
                                            id="conf-del"
                                            className="color-ppal hover-text-ppal"
                                        >
                                            Aceptar
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {selectedFunction && (
                    <div
                        className={
                            this.state.confirmBack
                                ? "modal fade modalAlert in"
                                : "modal fade modalAlert"
                        }
                        style={{
                            display: this.state.confirmBack ? "block" : "none",
                            zIndex: 99999999,
                        }}
                        id="modalConfirm"
                        role="dialog"
                    >
                        <div
                            className="modal-dialog modal-dialog-centered modal-md"
                            role="document"
                        >
                            <div className="modal-content">
                                <div className="conten-expired clear-fl">
                                    <div className="top">
                                        <i className="icon-alert color-b"></i>
                                    </div>
                                    <div className="content">
                                        <div className="title color-titulos">
                                            Posee puestos seleccionados para
                                            esta zona
                                        </div>
                                        <p className="color-texto-contenido">
                                            Si continúa perderá esas
                                            reservaciones.
                                        </p>
                                        <button
                                            onClick={this.clearTickets}
                                            id="confirmacionBack-continnue"
                                            className="color-ppal hover-text-ppal"
                                        >
                                            Continuar
                                        </button>
                                        <button
                                            onClick={async () => {
                                                this.setState({
                                                    confirmBack: false,
                                                });
                                            }}
                                            id="confirmacionBack-cancel"
                                            className=""
                                        >
                                            Cancelar
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </Fragment>
        );
    }
}

TicketsPre.propTypes = {
    setFunction: PropTypes.func.isRequired,
};

export default withTranslation()(React.memo(TicketsPre));

import React, { useEffect, Component } from "react";
import { useTranslation, withTranslation } from "react-i18next";
import Countdown from "react-countdown";
import { TimerS } from "../../utils/iconsSvg";

// Random component
const Completionist = ({ redirect, overTime }) => {
    const { t } = useTranslation();
    useEffect(() => {
        if (typeof overTime === "function") {
            overTime();
        }
    }, []);
    return (
        <div
            className="modal fade modalAlert in"
            id="modalExpired"
            role="dialog"
            style={{ display: "block", paddingRight: "17px" }}
        >
            <div
                className="modal-dialog modal-dialog-centered modal-md"
                role="document"
            >
                <div className="modal-content">
                    <div className="conten-expired clear-fl">
                        <div className="top">
                            <i className="icon-timer color-b"></i>
                        </div>
                        <div className="content">
                            <div className="title color-titulos">
                                {t("timeExpired")}
                            </div>
                            <p className="color-texto-contenido">
                                {t("timeAvailableExpired")}
                            </p>
                            {redirect ? (
                                <a
                                    href={redirect}
                                    className="color-ppal hover-text-ppal"
                                >
                                    {t("tryAgain").toUpperCase()}
                                </a>
                            ) : (
                                <a
                                    href=""
                                    data-dismiss="modal"
                                    aria-label="Close"
                                    className="color-ppal hover-text-ppal"
                                >
                                    {t("tryAgain").toUpperCase()}
                                </a>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

class TimerSmall extends Component {
    // Renderer callback with condition
    renderer = ({ hours, minutes, seconds, completed }) => {
        const { global, t, redirect, overTime, event } = this.props;
        if (completed) {
            localStorage.removeItem("reservationSeats");
            if (redirect) {
                document.location.href = redirect;
                return null;
            } else {
                return (
                    <Completionist redirect={redirect} overTime={overTime} />
                );
            }
        } else {
            console.log('minutes',minutes)
            // Render a countdown
            if (minutes > 6) {
                return <></>
            }
            return (
                <div style={{display: `flex`,alignItems: `center`,gap: `8px`,fontSize: `20px`}}>
                    <TimerS />
                    <b
                        className={`${
                            minutes === 0 && seconds <= 30
                                ? "parpadea"
                                : "color-ppal"
                        }`}
                        style={{ opacity: 1 }}
                    >
                        {minutes}:
                        {seconds < 10 ? `0${seconds}` : seconds}
                    </b>
                </div>
            );
        }
    };
    render() {
        const seconds = 1200000;//;//
        return (
            <Countdown date={Date.now() + seconds} renderer={this.renderer} />
        );
    }
}

export default withTranslation()(React.memo(TimerSmall));
